import {useState, useEffect} from "react";
import {useSelector} from 'react-redux';
import Avatar from 'react-avatar';
import {Link} from "react-router-dom";

import { Loading } from "_components";
import { fetchWrapper } from '_helpers';

export {Profile}

function Profile() {
    const authUser = useSelector(x => x.auth.user);
    const [profile, setProfile] = useState();
    const [company, setCompany] = useState();
    const [companyAddresses, setCompanyAddresses] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/profile").then(response => {
            setProfile(response);
            setLoading(false);
        });
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/profile/company").then(response => {
            setCompany(response);
        });
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/profile/company/addresses").then(response => {
            setCompanyAddresses(response);
        });

    }, [])

    return (
        <>
        {loading === false ?
        <>
        <div className="row justify-content-center">
            <div className="col">
                <h3 className="mt-4 text-center">My Profile</h3>
            </div>
        </div>
        <div className='mt-4 mb-4 border-bottom'></div>
        {company?
            <>
            <div className="row justify-content-center border-bottom mt-4">
            <div className="col-lg-8 col-xl-8">
                <div className="row">
                    <div className="col-3 text-center"><Avatar name={company.channelName} round={true} size="75" /></div>
                    <div className="col-9">
                        <h4 className='mb-2'>
                            {
                                authUser.roles.includes('ROLE_ACCOUNT_OWNER') === true ?
                                <Link to="/profile/company/edit" >{company.name}</Link>
                                : company.name
                            }
                        </h4>
                        <p className="mb-0">Industry: {company.industry}</p>
                        <p className="mb-0">Currency: {company.currency}</p>
                        <p className="mb-0"><a href={company.website}>{company.website}</a></p>
                        <p className="mb-0">Channel: <a href="#">{company.channelName}</a></p>
                        <div className="row mt-3">
                        {companyAddresses.map(companyAddress => 
                            <div className="col">
                                <Link to={"/profile/company/address" + companyAddress.id +  "/edit"} >{companyAddress.address1}</Link>
                                {companyAddress.address2 && <p className='mb-0'>{companyAddress.address2}</p>}
                                <p className='mb-0'>{companyAddress.city}, {companyAddress.state} {companyAddress.postalCode}</p>
                                <p className='mb-2'>{companyAddress.countryName}</p>
                            </div>
                        )}
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </>
        : null}
            <div className="row justify-content-center border-bottom mt-4">
                <div className="col-lg-8 col-xl-8">
                    <div className="row">
                        <div className="col-3 text-center"><Avatar name={profile.lastName + " " + profile.firstName} round={true} size="75" /></div>
                        <div className="col-9">
                            <h4 className='mb-2'><Link to="/profile/edit" >{profile.lastName} {profile.firstName}</Link></h4>
                            <p className='mb-0'>{profile.email}</p>
                            <p className='mb-2'>{profile.mobilePhone}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
        : <Loading/>}
        </>
    )

}