import {useEffect, useState} from "react";
import {useSearchParams, useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {fetchWrapper} from '_helpers';

export {
    VendorRegistration
}

function VendorRegistration() {
    const navigate = useNavigate();
    const [companyName, setCompanyName] = useState(null);
    let [searchParams] = useSearchParams()
    const code = searchParams.get("code")
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    // form validation rules
    const validationSchema = Yup.object({
        user: Yup.object(
            {
                firstName: Yup.string().required('Name is required field'),
                lastName: Yup.string().required('Name is required field'),
                email: Yup.string().required('Username is required field').email("Please enter a valid email address"),
                password: Yup.string().required('Password is required field').min(8, "Password must be at least 8 characters"),
                confirmPassword: Yup.string().oneOf(
                    [
                        Yup.ref('password'), null
                    ],
                    'Passwords must match'
                ),
                mobilePhone: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
            }
        )
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setValue,
        setError,
        getValues,
        setFocus
    } = useForm(formOptions);
    const {errors, isSubmitting} = formState;


    useEffect(() => {
        if (code) {
            fetchWrapper.get(process.env.REACT_APP_API_URL + "/register/vendor?code=" + code).then(response => {
                setValue("user.email", response.user?.email);
                setCompanyName(response.company?.name);
            });
        } else {
            navigate("/login");
        }
        setFocus("user.firstName");
    }, [code])

    const onSubmit = (data => {
        const values = getValues();
        console.log(values);
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/register/vendor?code=" + code, values).then(response => {
            console.log(response);
            navigate("/login");
        }).catch(response => {
            if (response.status === 530) {
                setError("user.email", { type: 'custom', message: "Email has been already registered." }, {shouldFocus: true});
            }
            console.log("Error: " + response.status);
        });
    })

    return (
        <>
            <div className="row justify-content-center">
                <div className="col">
                    <h1 className="mt-4 text-center">Sign Up</h1>
                    <p className="text-center text-uppercase">Vendor for company {companyName}</p>
                </div>
            </div>
            <div className='mt-4 mb-4 border-bottom'></div>
            <div className="row justify-content-center">
                <div className="col-lg-8 col-xl-5">
                    <form onSubmit={
                        handleSubmit(onSubmit)
                    }>
                        <div className="form-group mb-4">
                            <label className="mb-2">Name</label>
                            <input type="text" {...register('user.firstName')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.user ?. firstName ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.user ?. firstName ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Surname</label>
                            <input type="text" {...register('user.lastName')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.user ?. lastName ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.user ?. lastName ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">E-mail</label>
                            <input type="text" {...register('user.email')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.user ?. email ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.user ?. email ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Password</label>
                            <input type="password" {...register('user.password')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.user ?. password ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.user ?. password ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Retype password</label>
                            <input type="password" {...register('user.confirmPassword')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.user ?. confirmPassword ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.user ?. confirmPassword ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Mobile phone</label>
                            <input type="text" {...register('user.mobilePhone')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.user ?. mobilePhone ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.user ?. mobilePhone ?. message
                            }</div>
                        </div>
                        <div className='mt-4 mb-4 border-bottom'></div>
                        <div className="d-grid gap-2">
                            <button disabled={isSubmitting}
                                className="btn btn-lg btn-dark mt-3">
                                {
                                isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>
                            }
                                Sign up
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )


}
