import {useEffect, useState} from "react";
import {Link, useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {fetchWrapper} from '_helpers';


export {CompanyEdit}

function CompanyEdit() {
    const [industries, setIndustries] = useState(['GENERAL', 'PERSONAL', 'REALTORS', 'DEALERSHIP']);
    const [currencies, setCurrencies] = useState(['CAN', 'USD', 'EUR']);
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    // form validation rules
    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required field'),
        channelName: Yup.string().required('Channel name is required field'),
        address1: Yup.string().required('Address 1 is required field'),
        countryId: Yup.string().required('Country is required field'),
        industry: Yup.string().required('Industry is required field'),
        currency: Yup.string().required('Currency is required field'),
        website: Yup.string().url("Website must be a valid URL"),
        phoneNumber: Yup.string().notRequired().matches(phoneRegExp, 'Phone number is not valid')
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setValue,
        setError,
        getValues,
        setFocus
    } = useForm(formOptions);
    const {errors, isSubmitting} = formState;


    useEffect(() => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/profile/company").then(response => {
            setValue("name", response.name);
            setValue("channelName", response.channelName);
            setValue("address1", response.address1);
            setValue("industry", response.industry);
            setValue("currency", response.currency);
            setValue("website", response.website);
            setValue("phoneNumber", response.phoneNumber);
            setLoading(false);
        });
        setFocus("name");
    }, [])

    const onSubmit = () => {
        const values = getValues();
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/profile/company", values).then(response => {
            navigate("/profile");
        }).catch(response => {
            if (response.status === 531) {
                setError("channelName", { type: 'custom', message: "Channel name has been already registered." }, {shouldFocus: true});
            }
            console.log("Error: " + response.status);
        });;
    }

    return (
        <>
            <div className="row justify-content-center">
                <div className="col">
                    <h3 className="mt-4 text-center">Edit Company</h3>
                </div>
            </div>
            <div className='mt-4 mb-4 border-bottom'></div>
            <div className="row justify-content-center">
                <div className="col-lg-8 col-xl-5">
                    <form onSubmit={
                        handleSubmit(onSubmit)
                    }>
                        <div className="form-group mb-4">
                            <label className="mb-2">Company</label>
                            <input type="text" {...register('name')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.name ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.name ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Channel Name</label>
                            <input type="text" {...register('channelName')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.channelName ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.channelName ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Phone number</label>
                            <input type="text" {...register('phoneNumber')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.phoneNumber ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.phoneNumber ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Website</label>
                            <input type="text" {...register('website')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.website ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.website ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Address 1</label>
                            <input type="text" {...register('address1')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.address1 ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.address1 ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Address 2</label>
                            <input type="text" {...register('address2')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.address2 ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.address2 ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="country" className="mb-2">Country</label>
                            <select id="country" {...register('countryId')}
                                className={
                                    `form-control ${
                                        errors.countryId ? 'is-invalid' : ''
                                    }`
                            }>
                                {
                                countries ?. map((country, index) => <option key={index}
                                    value={
                                        country.id
                                }>
                                    {
                                    country.name
                                }</option>)
                            } </select>
                            <div className="invalid-feedback">
                                {
                                errors.country ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">State</label>
                            <input type="text" {...register('state')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.state ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.state ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Zip</label>
                            <input type="text" {...register('postalCode')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.postalCode ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.postalCode ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="industry" className="mb-2">Industry</label>
                            <select id="industry" {...register('industry')}
                                className={
                                    `form-control ${
                                        errors.industry ? 'is-invalid' : ''
                                    }`
                            }>
                                <option defaultValue=" ">Choose...</option>
                                {
                                industries ?. map((industry, index) => <option key={index}
                                    value={industry}>
                                    {industry}</option>)
                            } </select>
                            <div className="invalid-feedback">
                                {
                                errors.industry ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="currency" className="mb-2">Currency</label>
                            <select id="currency" {...register('currency')}
                                className={
                                    `form-control ${
                                        errors.currency ? 'is-invalid' : ''
                                    }`
                            }>
                                <option defaultValue=" ">Choose...</option>
                                {
                                currencies ?. map((currency, index) => <option key={index}
                                    value={currency}>
                                    {currency}</option>)
                            } </select>
                            <div className="invalid-feedback">
                                {
                                errors.currency ?. message
                            }</div>
                        </div>
                        <div className='mt-4 mb-4 border-bottom'></div>
                        <div className="d-grid gap-2 mb-3">
                            <button disabled={isSubmitting}
                                className="btn btn-lg btn-dark mt-3">
                                {
                                isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>
                            }
                                Save
                            </button>
                            <Link to="/profile" className="btn btn-light" >Cancel</Link>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}