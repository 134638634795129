import { useSelector } from 'react-redux';

export {
    Dashboard
}

function Dashboard() {
    const { user: authUser } = useSelector(x => x.auth);


    return (
        <>
            <div className="row justify-content-center">
                <div className="col-lg-11 col-xl-9" >Company {authUser.companyName} dashboard</div>
                <div className="col-lg-11 col-xl-9" >{authUser.roles}</div>
            </div>
        </>
    )

}