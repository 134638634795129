import {useState, useEffect} from "react";
import ReactPaginate from 'react-paginate';
import {useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';

import {fetchWrapper, history} from '_helpers';

export {
    AccountOwnerInvitation
};

function AccountOwnerInvitation() {
    const [invitations, setInvitations] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required').email("Please enter a valid email address")
    });
    const formOptions = { resolver: yupResolver(validationSchema), criteriaMode: "firstError", shouldFocusError: true };
    const {register, handleSubmit, formState, getValues, setError, setFocus} = useForm(formOptions);
    const {errors, isSubmitting} = formState;

    useEffect(() => {
        setFocus("email")
        loadInvitations(0);
    }, [])

    function onSubmit() {
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/invite/accountowner", getValues()).then(response => {
            loadInvitations(0);
        }).catch(response => {
            if (response.status === 530) {
                setError("email", { type: 'server', message: "Email has been already registered." }, {shouldFocus: true});
            }
        });
    }

    function loadInvitations(page) {
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/admin/companies/invitations?page=" + page).then(response => {
            setInvitations(response.content);
            setTotalPages(response.totalPages);
        })
    }

    const handlePageClick = (event) => {
        loadInvitations(event.selected);
    };

    return (
        <>
            <div className="row justify-content-center">
                <div className="col">
                    <h3 className="mt-4 text-center">Invite Account Owner</h3>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-8 col-xl-5">
                    <form onSubmit={
                        handleSubmit(onSubmit)
                    }>
                        <div className="form-group mb-4">
                            <label className="mb-2">Email</label>
                            <input type="text" {...register('email')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.email ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.email?.message
                            }</div>
                        </div>
                        {/*
                        {showMessage &&
                            <div className="alert alert-warning mt-3 mb-0">Sent successfully</div>
                        }
                        */}
                        <div className="d-grid gap-2">
                            <button disabled={isSubmitting}
                                className="btn btn-dark mt-3">
                                {
                                isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>
                            }
                                Send Invitation
                            </button>
                        </div>
                    </form>
                    {invitations.length > 0 ?
                        <>
                        <table className="table mt-4">
                            <thead>
                                <tr>
                                    <td className="w-50">Invitee</td>
                                    <td>Company</td>
                                </tr>
                            </thead>
                            <tbody>
                                {invitations.map(invitation => 
                                    <tr key={invitation.id}>
                                        <td>
                                            {invitation.inviteeEmail}
                                            <p className="small">{moment(invitation.creationTime).format('Do MMM YYYY HH:mm')}</p>
                                        </td>
                                        <td>{invitation.inviteeCompanyName}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {totalPages > 1 ?
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Next"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={totalPages}
                                previousLabel="Previous"
                                renderOnZeroPageCount={null}
                                className="pagination justify-content-center"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                activeClassName="page-item active"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                            />
                        : null}
                        </>
                        : null}
                </div>
            </div>
        </>
    )

}
