import {useNavigate, useParams} from 'react-router-dom';
import {useState, useEffect} from "react";
import Avatar from 'react-avatar';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

import { fetchWrapper } from '_helpers';

export {
    Company
};

function Company() {
    const params = useParams();
    const navigate = useNavigate();
    const [company, setCompany] = useState(null);
    const [addresses, setAddresses] = useState([]);
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [companyOwner, setCompanyOwner] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/admin/companies/" + params.companyId).then(response => {
            setCompany(response.company);
            setAddresses(response.addresses);
            setPhoneNumbers(response.phoneNumbers);
            setLoading(false);
        });
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/admin/companies/" + params.companyId + "/owner").then(response => {
            setCompanyOwner(response);
        });
    }, [params.companyId]);

    const goBack = () => {
        navigate("/");
    };

    return (
        <>
        {loading === false ?
            <>
            {/*
            <div className='row'>
                <div className='col'>
                <button className="btn btn-link"
                    onClick={goBack}>Back to list</button>
                </div>
            </div>
            */}
            <div className='row text-center'>
                <div className='col border-bottom'>
                <h1 className='mb-0'>{company?.name}</h1>
                <p>Member - {moment(company.registrationDate).format('MMM Do YYYY')}</p>
                </div>  
            </div>
            <div className='row mt-4'>
                <div className='col-lg-6'>
                    <h6>Company</h6>
                    <div className='row'>
                    <div className='col-3 text-center'>
                        <Avatar name={company.channelName} round={true} size="75" />
                    </div>
                    <div className='col-9'>
                        {addresses.map(address =>
                            <div key={address.id}>
                                <p className='mb-0'>{address.address1}</p>
                                {address.address2 && <p className='mb-0'>{address.address2}</p>}
                                <p className='mb-0'>{address.city}, {address.state} {address.postalCode}</p>
                                <p className='mb-2'>{address.countryName}</p>
                            </div>
                        )}
                        {phoneNumbers.map(phoneNumber =>
                            <div key={phoneNumber.id}>
                                <a className='mb-0' href={"tel:" + phoneNumber.phoneNumber}><FontAwesomeIcon icon={faPhoneAlt} /> {phoneNumber.phoneNumber}</a>
                            </div>
                        )}
                    </div>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <h6>Contact</h6>
                    <div className='row'>
                    <div className='col-3 text-center'>
                        <Avatar name={companyOwner?.lastName + " " + companyOwner?.firstName} round={true} size="75" />
                    </div>
                    <div className='col-9'>
                        <p className='mb-0'>{companyOwner?.lastName} {companyOwner?.firstName}</p>
                        <p className='mb-0'>{companyOwner?.email}</p>
                        <a className='mb-0' href={"tel:" + companyOwner.mobilePhone}><FontAwesomeIcon icon={faPhoneAlt} /> {companyOwner.mobilePhone}</a>
                    </div>
                    </div>
                </div>
            </div>
            <div className='mt-4 mb-4 border-bottom'></div>
            <div className='row'>
                <div className='col-lg-8'>
                    <div className='row'>
                    <div className='col-3'>
                        <h6>Active offers</h6>
                        <h5>2</h5>
                    </div>
                    <div className='col-3'>
                        <h6>Total offers</h6>
                        <h5>4</h5>
                    </div>
                    </div>
                </div>
                <div className='col-lg-4'></div>

            </div>
            <div className='mt-4 border-bottom'></div>
            </>
        : null
        }
        </>
    )
}
