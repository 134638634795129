import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';

import { history } from '_helpers';
import { Nav, PrivateRoute } from '_components';
import { Home, Profile, ProfileEdit } from 'home';
import { Login, PasswordForget } from 'login';
import { AdminCompany, Contacts } from 'admin';
import { AccountOwnerRegistration, VendorRegistration } from 'registration';
import {AccountOwnerInvitation, VendorInvitation} from 'invitation';
import {CompanyAddressEdit, CompanyEdit, Vendors} from 'accountowner';

export { App };

function App() {
    // init custom history object to allow navigation from 
    // anywhere in the react app (inside or outside components)
    history.navigate = useNavigate();
    history.location = useLocation();

    return (
        <div className="app-container">
            <Nav />
            <div className="container">
                <Routes>
                    <Route
                        path="/"
                        element={
                            <PrivateRoute>
                                <Home />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/admin/company/:companyId"
                        element={
                            <PrivateRoute>
                                <AdminCompany />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/admin/invitation"
                        element={
                            <PrivateRoute>
                                <AccountOwnerInvitation />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/admin/contacts"
                        element={
                            <PrivateRoute>
                                <Contacts />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/profile"
                        element={
                            <PrivateRoute>
                                <Profile />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/profile/edit"
                        element={
                            <PrivateRoute>
                                <ProfileEdit />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/profile/company/edit"
                        element={
                            <PrivateRoute>
                                <CompanyEdit />
                            </PrivateRoute>
                        }
                    />

                    
                    <Route
                        path="/profile/company/address/:addressId/edit"
                        element={
                            <PrivateRoute>
                                <CompanyAddressEdit />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/invitation/vendor"
                        element={
                            <PrivateRoute>
                                <VendorInvitation />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/vendors"
                        element={
                            <PrivateRoute>
                                <Vendors />
                            </PrivateRoute>
                        }
                    />
                    
                    <Route path="/login" element={<Login />} />
                    <Route path="/password/forget" element={<PasswordForget />} />
                    <Route path="/register" element={<AccountOwnerRegistration />} />
                    <Route path="/register/vendor" element={<VendorRegistration />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </div>
        </div>
    );
}
