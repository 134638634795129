import {Link, NavLink} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBell, faGear} from '@fortawesome/free-solid-svg-icons'

import {authActions} from '_store';
import { AccountOwnerNav } from 'accountowner';
import { AdminNav } from 'admin';

export {
    Nav
};

function Nav() {
    const authUser = useSelector(x => x.auth.user);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());

    // only show nav when logged in
    if (!authUser) 
        return null;
    
    if (authUser.roles.includes('ROLE_ACCOUNT_OWNER')) {
        return <AccountOwnerNav/>
    }
    if (authUser.roles.includes('ROLE_ADMIN')) {
        return <AdminNav/>
    }

    return (
        <header className="p-3 mb-3 border-bottom"
            style={
                {backgroundColor: "#5841D8"}
        }>
            <div className="container">
                <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                    <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-decoration-none">
                        <img src="navbar.svg" alt="" width="40" height="40"/>
                    </a>

                    {
                    authUser.roles.includes('ROLE_ADMIN') === true && <>
                        <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                            <li>
                                <NavLink to="/admin/companies" className="nav-item nav-link text-light">Accounts</NavLink>
                            </li>
                            <li>
                                <NavLink to="/admin/offers" className="nav-item nav-link text-light">Offers</NavLink>
                            </li>
                            <li>
                                <NavLink to="/admin/spaces" className="nav-item nav-link text-light">Spaces</NavLink>
                            </li>
                            <li>
                                <NavLink to="/admin/contacts" className="nav-item nav-link text-light">Contacts</NavLink>
                            </li>
                        </ul>

                        <ul className="nav col-12 col-lg-auto mb-2 justify-content-center mb-md-0">
                            <li>
                                <NavLink to="/notifications" className="nav-item nav-link text-light"><FontAwesomeIcon icon={faBell}/></NavLink>
                            </li>
                            <li>
                                <NavLink to="/settings" className="nav-item nav-link text-light"><FontAwesomeIcon icon={faGear}/></NavLink>
                            </li>
                        </ul>
                    </>
                }

                    {
                    authUser.roles.includes('ROLE_VENDOR') === true && <>
                        <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                            <li>
                                <NavLink to="/offers" className="nav-item nav-link text-light">Offers</NavLink>
                            </li>
                            <li>
                                <NavLink to="/spaces" className="nav-item nav-link text-light">Spaces</NavLink>
                            </li>
                            <li>
                                <NavLink to="/contacts" className="nav-item nav-link text-light">Contacts</NavLink>
                            </li>
                        </ul>
                        <ul className="nav col-12 col-lg-auto mb-2 justify-content-center mb-md-0">
                            <li>
                                <NavLink to="/notifications" className="nav-item nav-link text-light"><FontAwesomeIcon icon={faBell}/></NavLink>
                            </li>
                        </ul>
                    </>
                }
                    <div className="dropdown">
                        <a href="#" className="d-block text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            {
                            authUser.lastName + " " + authUser.firstName
                        }</a>
                        <ul className="dropdown-menu text-small dropdown-menu-purple">
                            <li>
                                <Link to="/profile" className='dropdown-item'>My Profile</Link>
                            </li>
                            <li>
                                <Link to="/password/change" className='dropdown-item'>Manage Password</Link>
                            </li>
                            <li><hr className="dropdown-divider"/></li>
                            <li>
                                <a className="dropdown-item" onClick={logout}>Sign out</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );

}

