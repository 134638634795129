import {Link, NavLink} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBell, faGear, faUsers} from '@fortawesome/free-solid-svg-icons'

import {authActions} from '_store';

export {
    Nav
};

function Nav() {
    const authUser = useSelector(x => x.auth.user);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());

    return (
        <header className="p-3 mb-3" style={
            {backgroundColor: "#5841D8"}
    }>
            <div className="container">
                <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                    <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-decoration-none">
                        <img src="navbar.svg" alt="" width="40" height="40"/>
                    </a>
                    <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                        <li>
                            <NavLink to="/offers" className="nav-item nav-link text-light">Offers</NavLink>
                        </li>
                        <li>
                            <NavLink to="/spaces" className="nav-item nav-link text-light">Spaces</NavLink>
                        </li>
                        <li>
                            <NavLink to="/contacts" className="nav-item nav-link text-light">Contacts</NavLink>
                        </li>
                    </ul>

                    <ul className="nav col-12 col-lg-auto mb-2 justify-content-center mb-md-0">
                        <li>
                            <NavLink to="/notifications" className="nav-item nav-link text-light"><FontAwesomeIcon icon={faBell}/></NavLink>
                        </li>
                    </ul>

                    <div className="dropdown me-3">
                        <a href="#" className="d-block text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <FontAwesomeIcon icon={faGear}/></a>
                    <div class="dropdown-menu dropdown-menu-purple">
                        <div className='row'>
                            <div className='col d-flex align-items-start'>
                            <NavLink to="/vendors" className="nav-item nav-link nav-dark"><FontAwesomeIcon icon={faUsers}/> User Management
                                <p className='small'>Manage Users and their privileges</p>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="dropdown">
                        <a href="#" className="d-block text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            {
                            authUser.lastName + " " + authUser.firstName
                        }</a>
                        <ul className="dropdown-menu text-small dropdown-menu-purple">
                            <li>
                                <Link to="/profile/manage" className='dropdown-item'>Manage Account</Link>
                            </li>
                            <li><hr className="dropdown-divider"/></li>
                            <li>
                                <Link to="/profile" className='dropdown-item'>My Profile</Link>
                            </li>
                            <li>
                                <Link to="/password/change" className='dropdown-item'>Manage Password</Link>
                            </li>
                            <li><hr className="dropdown-divider"/></li>
                            <li>
                                <a className="dropdown-item"
                                    onClick={logout}>Sign out</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );

}
