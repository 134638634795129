import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import { history } from '_helpers';
import { authActions } from '_store';
import { Link } from 'react-router-dom';

export { Login };

function Login() {
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const authError = useSelector(x => x.auth.error);
    // form validation rules 
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Email is required').email("Please enter a valid email address"),
        password: Yup.string().required('Password is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, setFocus, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        // redirect to home if already logged in
        setFocus("username");
        if (authUser) history.navigate('/');
    }, []);


    function onSubmit({ username, password }) {
        return dispatch(authActions.login({ username, password }));
    }

    return (
        <div className="row">
            <div className="col-md-6 text-center">
                <img src="logo.svg" alt="logo" className='mt-5' />
            </div>
            <div className="col-md-6">
                <h3 className='text-center mt-5'>Sign in</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group mb-4">
                        <label className="mb-2">Email</label>
                        <input name="username" type="text" {...register('username')} maxLength="30" className={`form-control ${errors.username ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.username?.message}</div>
                    </div>
                    <div className="form-group">
                        <label className="mb-2">Password</label>
                        <input name="password" type="password" {...register('password')} maxLength="30" className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.password?.message}</div>
                    </div>
                    <div className="d-grid gap-2">
                    <button disabled={isSubmitting} className="btn btn-dark mt-3">
                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                        Sign up
                    </button>
                    </div>
                    {authError &&
                        <div className="alert alert-danger mt-3 mb-0">Please check credentials and try again.</div>
                    }
                    <div className="mt-3">
                        <p className='mb-0'><Link to="/password/forget" >Forget password?</Link></p>
                        <p><Link to="/register" >New to Abccrm.app - Sign up</Link></p>
                    </div>
                </form>
            </div>
        </div>
    )
}
