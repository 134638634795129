import {useState, useEffect} from "react";
import ReactPaginate from 'react-paginate';
import {Link} from "react-router-dom";
import {useForm} from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import Avatar from 'react-avatar';
import moment from "moment";

import { fetchWrapper } from '_helpers';
import { Loading, NoResults } from "_components";

export {
    Dashbboard
};

function Dashbboard() {
    const { register, watch, getValues, setValue } = useForm();
    const searchPattern = watch('searchPattern');
    const [loading, setLoading] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        loadAccounts(0);
    }, [searchPattern])

    function loadAccounts(page) {
        //setLoading(true);
        setValue("pageSize", 10, {shouldTouch:true});
        setValue("pageNumber", page, {shouldTouch:true});
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/admin/companies", getValues()).then(response => {
            setCompanies(response.content);
            setTotalPages(response.totalPages);
            //setLoading(false);
        });
    }

    const handlePageClick = (event) => {
        loadAccounts(event.selected);
    };


    return (
        <div className="row justify-content-center">
            <div className="col-lg-11 col-xl-9">
                <div className="mt-5 mb-3">
                    <input type="text" className="form-control" name="searchPattern" {...register('searchPattern')} placeholder="Search accounts"/>
                </div>
                <div className="">
                    <Link to="/admin/invitation">Add new</Link>
                </div>
                {loading === false ?
                <>
                    {companies.length > 0 ?
                    <>
                    <div className='mt-4 border-bottom'></div>
                    <table className="table">
                        <tbody>
                            {companies.map(company =>          
                                <tr key={company.id}>
                                    <td className="align-middle w-10">
                                        <Avatar name={company.channelName} round={true} size="50" />
                                    </td>
                                    <td className="align-middle w-50">
                                        <Link to={"/admin/company/" + company.id}>{company.name}</Link>
                                        <p>Member - {moment(company.registrationDate).format('MMM Do YYYY')}</p>
																						  
                                    </td>
                                    <td className="align-middle text-center">
																										
                                        <h3 className="mb-0">{company.numberOfActiveOffers}</h3>
                                        <p>Active offers</p>
                                    </td>
                                    <td className="align-middle text-center">
                                        <h3 className="mb-0">{company.numberOfOffers}</h3>
                                        <p>Total offers</p>
                                    </td>
                                    <td className="align-middle text-center">
                                        <a href={"tel:" + company.phoneNumber} className="btn btn-light"><FontAwesomeIcon icon={faPhoneAlt} /></a>
                                        <a className="btn btn-light"><FontAwesomeIcon icon={faEllipsis} /></a>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {totalPages > 1 ?
                    <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={totalPages}
                            previousLabel="Previous"
                            renderOnZeroPageCount={null}
                            className="pagination justify-content-center"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            activeClassName="page-item active"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                        />
                        : null}
                    </>
                    : <NoResults />}
                </>
                : <Loading/>
                }
            </div>
        </div>
    );

}
