import { useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

import { fetchWrapper, history } from '_helpers';

export { PasswordForget };

function PasswordForget() {
    const navigate = useNavigate();
    const authUser = useSelector(x => x.auth.user);
    const authError = useSelector(x => x.auth.error);
    // form validation rules 
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Email is required').email("Please enter a valid email address")
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, setFocus, getValues, setError, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        // redirect to home if already logged in
        setFocus("username");
        if (authUser) history.navigate('/');
    }, []);


    function onSubmit() {
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/password/forget", getValues()).then(response => {
            navigate("/login");
        }).catch(response => {
            if (response.status === 532) {
                setError("username", { type: 'custom', message: "Email has not been registered." }, {shouldFocus: true});
            }
        });
    }

    return (
        <div className="row">
            <div className="col-md-6 text-center">
                <img src="logo.svg" alt="logo" className='mt-5' />
            </div>
            <div className="col-md-6">
                <h3 className='text-center mt-5'>Password forget</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group mb-4">
                        <label className="mb-2">Email</label>
                        <input name="username" type="text" {...register('username')} maxLength="30" className={`form-control ${errors.username ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.username?.message}</div>
                    </div>
                    <div className="d-grid gap-2">
                    <button disabled={isSubmitting} className="btn btn-dark mt-3">
                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                        Generate new
                    </button>
                    </div>
                    {authError &&
                        <div className="alert alert-danger mt-3 mb-0">{authError.message}</div>
                    }
                </form>
            </div>
        </div>
    )
}
