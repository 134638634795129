import {useEffect, useState} from "react";
import {Link, useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {fetchWrapper} from '_helpers';

export {
    ProfileEdit
}

function ProfileEdit() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    // form validation rules
    const validationSchema = Yup.object({
        firstName: Yup.string().required('Firstname is required field'),
        lastName: Yup.string().required('Lastname is required field'),
        email: Yup.string().required('Email is required field').email("Please enter a valid email address"),
        mobilePhone: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setValue,
        setError,
        getValues,
        setFocus
    } = useForm(formOptions);
    const {errors, isSubmitting} = formState;


    useEffect(() => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/profile").then(response => {
            setValue("email", response.email);
            setValue("lastName", response.lastName);
            setValue("firstName", response.firstName);
            setValue("mobilePhone", response.mobilePhone);
            setLoading(false);
        });
        setFocus("firstName");
    }, [])

    const onSubmit = () => {
        const values = getValues();
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/profile", values).then(response => {
            navigate("/profile");
        });
    }

    return (
        <>
            <div className="row justify-content-center">
                <div className="col">
                    <h3 className="mt-4 text-center">Edit Profile</h3>
                </div>
            </div>
            <div className='mt-4 mb-4 border-bottom'></div>
            <div className="row justify-content-center">
                <div className="col-lg-8 col-xl-5">
                    <form onSubmit={
                        handleSubmit(onSubmit)
                    }>
                        <div className="form-group mb-4">
                            <label className="mb-2">Name</label>
                            <input type="text" {...register('firstName')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.firstName ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.firstName ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Surname</label>
                            <input type="text" {...register('lastName')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.lastName ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.lastName ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">E-mail</label>
                            <input type="text" {...register('email')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.email ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.email ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Mobile phone</label>
                            <input type="text" {...register('mobilePhone')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.mobilePhone ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.mobilePhone ?. message
                            }</div>
                        </div>
                        <div className='mt-4 mb-4 border-bottom'></div>
                        <div className="d-grid gap-2 mb-3">
                            <button disabled={isSubmitting}
                                className="btn btn-lg btn-dark mt-3">
                                {
                                isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>
                            }
                                Save
                            </button>
                            <Link to="/profile" className="btn btn-light" >Cancel</Link>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}