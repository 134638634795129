export {
    Loading
}

function Loading() {
    return (
        <div className="text-center loading">
            <div className="spinner-border"></div>
        </div>
    )
}
