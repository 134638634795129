import {useState, useEffect} from "react";
import {useSearchParams, useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {fetchWrapper} from '_helpers';

export {
    AccountOwnerRegistration
}

function AccountOwnerRegistration() {
    const navigate = useNavigate();
    let [searchParams] = useSearchParams()
    const code = searchParams.get("code");
    const [industries, setIndustries] = useState(['GENERAL', 'PERSONAL', 'REALTORS', 'DEALERSHIP']);
    const [currencies, setCurrencies] = useState(['CAN', 'USD', 'EUR']);
    const [countries, setCountries] = useState([]);
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    // form validation rules
    const validationSchema = Yup.object({
        user: Yup.object(
            {
                firstName: Yup.string().required('Name is required field'),
                lastName: Yup.string().required('Name is required field'),
                email: Yup.string().required('Username is required field').email("Please enter a valid email address"),
                password: Yup.string().required('Password is required field').min(8, "Password must be at least 8 characters"),
                confirmPassword: Yup.string().oneOf(
                    [
                        Yup.ref('password'), null
                    ],
                    'Passwords must match'
                ),
                mobilePhone: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
            }
        ),
        company: Yup.object(
            {
                name: Yup.string().required('Name is required field'),
                channelName: Yup.string().required('Channel name is required field'),
                industry: Yup.string().required('Industry is required field'),
                currency: Yup.string().required('Currency is required field'),
                website: Yup.string().url("Website must be a valid URL"),
                address: Yup.object({
                    address1: Yup.string().required('Address 1 is required field'), 
                    countryId: Yup.string().required('Country is required field'), 
                    postalCode: Yup.string().required('Zip is required field'),
                    city: Yup.string().required('City is required field')
                }),
                phoneNumber: Yup.object({
                    value: Yup.string().notRequired().matches(phoneRegExp, 'Phone number is not valid'),
                })
            }
        )
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setValue,
        setError,
        getValues,
        setFocus
    } = useForm(formOptions);
    const {errors, isSubmitting} = formState;


    useEffect(() => {
        if (code) {
            let url = process.env.REACT_APP_API_URL + "/register/accountowner";
            if (code) {
                url += "?code=" + code;
            }
            fetchWrapper.get(url).then(response => {
                setValue("user.email", response.user ?. email);
            });
        }
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/public/countries").then(response => {
            setCountries(response.content);
        });
        setValue("company.industry", "GENERAL");
        setValue("company.currency", "CAN");
        setFocus("user.firstName");
    }, [code])

    const onSubmit = (data => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value);
            console.log(key + " | " + value);
        }

        const values = getValues();
        console.log(values);

        let url = process.env.REACT_APP_API_URL + "/register/accountowner";
        if (code) {
            url += "?code=" + code;
        }
        fetchWrapper.post(url, values).then(response => {
            console.log(response);
            navigate("/login");
        }).catch(response => {
            if (response.status === 530) {
                setError("user.email", {
                    type: 'custom',
                    message: "Email has been already registered."
                }, {shouldFocus: true});
            }
            if (response.status === 531) {
                setError("company.channelName", {
                    type: 'custom',
                    message: "Channel name has been already registered."
                }, {shouldFocus: true});
            }
            console.log("Error: " + response.status);
        });
    })

    return (
        <>
            <div className="row justify-content-center">
                <div className="col">
                    <h1 className="mt-4 text-center">Sign Up</h1>
                    <p className="text-center text-uppercase">Account owner</p>
                </div>
            </div>
            <div className='mt-4 mb-4 border-bottom'></div>
            <div className="row justify-content-center">
                <div className="col-lg-8 col-xl-5">
                    <form onSubmit={
                        handleSubmit(onSubmit)
                    }>
                        <div className="form-group mb-4">
                            <label className="mb-2">Name</label>
                            <input type="text" {...register('user.firstName')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.user ?. firstName ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.user ?. firstName ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Surname</label>
                            <input type="text" {...register('user.lastName')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.user ?. lastName ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.user ?. lastName ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">E-mail</label>
                            <input type="text" {...register('user.email')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.user ?. email ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.user ?. email ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Password</label>
                            <input type="password" {...register('user.password')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.user ?. password ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.user ?. password ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Retype password</label>
                            <input type="password" {...register('user.confirmPassword')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.user ?. confirmPassword ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.user ?. confirmPassword ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Mobile phone</label>
                            <input type="text" {...register('user.mobilePhone')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.user ?. mobilePhone ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.user ?. mobilePhone ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label class="form-label">Profile image</label>
                            <input class="form-control" type="file"/>
                        </div>
                        <div className='mt-5 mb-5 border-bottom'></div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Company</label>
                            <input type="text" {...register('company.name')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.company ?. name ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.company ?. name ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Channel Name</label>
                            <input type="text" {...register('company.channelName')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.company ?. channelName ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.company ?. channelName ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Phone number</label>
                            <input type="text" {...register('company.phoneNumber.value')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.company?.phoneNumber?.value ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.company?.phoneNumber?.value.message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Website</label>
                            <input type="text" {...register('company.website')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.company ?. website ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.company ?. website ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Address 1</label>
                            <input type="text" {...register('company.address.address1')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.company ?. address ?. address1 ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.company ?. address ?. address1 ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Address 2</label>
                            <input type="text" {...register('company.address.address2')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.company ?. address ?. address2 ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.company ?. address ?. address2 ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="country" className="mb-2">Country</label>
                            <select id="country" {...register('company.address.countryId')}
                                className={
                                    `form-control ${
                                        errors.company ?. address ?. countryId ? 'is-invalid' : ''
                                    }`
                            }>
                                {
                                countries ?. map((country, index) => <option key={index}
                                    value={
                                        country.id
                                }>
                                    {
                                    country.name
                                }</option>)
                            } </select>
                            <div className="invalid-feedback">
                                {
                                errors.company ?. address ?. countryId ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">State</label>
                            <input type="text" {...register('company.address.state')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.company ?. address ?. state ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.company ?. address ?. state ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">City</label>
                            <input type="text" {...register('company.address.city')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.company ?. address ?. city ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.company ?. address ?. city ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Zip</label>
                            <input type="text" {...register('company.address.postalCode')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.company ?. address ?. postalCode ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.company ?. address ?. postalCode ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="industry" className="mb-2">Industry</label>
                            <select id="industry" {...register('company.industry')}
                                className={
                                    `form-control ${
                                        errors.company ?. industry ? 'is-invalid' : ''
                                    }`
                            }>
                                <option defaultValue=" ">Choose...</option>
                                {
                                industries ?. map((industry, index) => <option key={index}
                                    value={industry}>
                                    {industry}</option>)
                            } </select>
                            <div className="invalid-feedback">
                                {
                                errors.company ?. industry ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="currency" className="mb-2">Currency</label>
                            <select id="currency" {...register('company.currency')}
                                className={
                                    `form-control ${
                                        errors.company ?. currency ? 'is-invalid' : ''
                                    }`
                            }>
                                <option defaultValue=" ">Choose...</option>
                                {
                                currencies ?. map((currency, index) => <option key={index}
                                    value={currency}>
                                    {currency}</option>)
                            } </select>
                            <div className="invalid-feedback">
                                {
                                errors.company ?. currency ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label class="form-label">Company logo</label>
                            <input class="form-control" type="file" accept='image/jpeg'/>
                        </div>
                        <div className='mt-4 mb-4 border-bottom'></div>
                        <div className="d-grid gap-2 mb-4">
                            <button disabled={isSubmitting}
                                className="btn btn-lg btn-dark mt-3">
                                {
                                isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>
                            }
                                Sign up
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )


}
