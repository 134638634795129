import {useEffect, useState} from "react";
import {Link, useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {fetchWrapper} from '_helpers';

export {
    CompanyAddressEdit
}

function CompanyAddressEdit() {
    const [countries, setCountries] = useState([]);
    // form validation rules
    const validationSchema = Yup.object({
        address1: Yup.string().required('Address 1 is required field'),
        countryId: Yup.string().required('Country is required field'),
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setValue,
        setError,
        getValues,
        setFocus
    } = useForm(formOptions);
    const {errors, isSubmitting} = formState;

    const onSubmit = (data => {

    });

    return (
        <>
            <div className="row justify-content-center">
                <div className="col">
                    <h1 className="mt-4 text-center">Edit Address</h1>
                </div>
            </div>
            <div className='mt-4 mb-4 border-bottom'></div>
            <div className="row justify-content-center">
                <div className="col-lg-8 col-xl-5">
                    <form onSubmit={
                        handleSubmit(onSubmit)
                    }>
                        <div className="form-group mb-4">
                            <label className="mb-2">Address 1</label>
                            <input type="text" {...register('company.address1')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.company ?. address1 ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.company ?. address1 ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Address 2</label>
                            <input type="text" {...register('company.address2')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.company ?. address2 ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.company ?. address2 ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="country" className="mb-2">Country</label>
                            <select id="country" {...register('company.countryId')}
                                className={
                                    `form-control ${
                                        errors.company ?. countryId ? 'is-invalid' : ''
                                    }`
                            }>
                                {
                                countries ?. map((country, index) => <option key={index}
                                    value={
                                        country.id
                                }>
                                    {
                                    country.name
                                }</option>)
                            } </select>
                            <div className="invalid-feedback">
                                {
                                errors.company ?. country ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">State</label>
                            <input type="text" {...register('company.state')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.company ?. state ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.company ?. state ?. message
                            }</div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="mb-2">Zip</label>
                            <input type="text" {...register('company.postalCode')} maxLength="30"
                                className={
                                    `form-control ${
                                        errors.company ?. postalCode ? 'is-invalid' : ''
                                    }`
                                }/>
                            <div className="invalid-feedback">
                                {
                                errors.company ?. postalCode ?. message
                            }</div>
                        </div>
                        <div className='mt-4 mb-4 border-bottom'></div>
                        <div className="d-grid gap-2">
                            <button disabled={isSubmitting}
                                className="btn btn-lg btn-dark mt-3">
                                {
                                isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>
                            }
                                Sign up
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}